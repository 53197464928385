import React, { useState } from "react";
import "./App.css";
import jsPDF from "jspdf";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function App() {
  const [text, setText] = useState("");
  const [qrCodeImages, setQRCodeImages] = useState([]);
  const [qrCodeSerial, setQrCodeSerial] = useState([]);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [showTextArea, setShowTextArea] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [showbackbtn, setShowbackbtn] = useState(false);
  const [showDownloadbtn, setShowDownloadbtn] = useState(false);
  const [showOverAll, setShowOverAll] = useState(false);


  //const [inputText, setInputText] = useState([]);

  const fontSize = 12;
  const textArray = [];
  const qrCodeArray = [];
  const handleSubmit = async (event) => {
    event.preventDefault();
    // let startTime = Date.now();
    // console.log("start time: ", startTime);
    setShowTextArea(false);
    setShowDownloadbtn(true);
    setShowImage(true);
    setShowbackbtn(true);
    setShowOverAll(true);
    try {
      const response = await fetch("https://qr.prismworks.io/generateQRCode", {
      // const response = await fetch("http://137.184.96.82/generateQRCode", {
      // const response = await fetch("http://localhost:3001/generateQRCode", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text }),
      });

      const { qrCodeImages } = await response.json();
      //console.log(qrCodeImages);
      await qrCodeImages.map((qrCodeImage) => {
        const [text, qrCode] = qrCodeImage.split("=");
        textArray.push(text);
        qrCodeArray.push(qrCode);
        return { text, qrCode };
      });
      //console.log("text: ", textArray);
      //console.log("qrcode: ", qrCodeArray);
      setQRCodeImages(qrCodeArray);
      setQrCodeSerial(textArray);
      convertImagesToPDF(qrCodeArray, textArray);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  function convertImagesToPDF(base64Images, textArray) {
    const doc = new jsPDF();

    // Generate PDF content
    const imageWidth = 80; // Width of each image
    const imageHeight = 80; // Height of each image
    const margin = 10; // Margin between images
    const startX = 10; // Starting X position
    const startY = 10; // Starting Y position

    let currentX = startX;
    let currentY = startY;

    base64Images.forEach((base64Image, index) => {
      const textValue = textArray[index];
      if (index !== 0 && index % 4 === 0) {
        // Check if we need to start a new page
        doc.addPage();
        currentX = startX;
        currentY = startY;
      }
      const serialNumber = `${textValue}`;

      // Add the QR code image
      doc.addImage(
        base64Image,
        "PNG",
        currentX,
        currentY,
        imageWidth,
        imageHeight
      );

      // Add the serial number below the QR code image
      doc.setFontSize(fontSize);
      doc.text(serialNumber, currentX + 12, currentY + imageHeight);

      // Calculate the next position
      currentX += imageWidth + margin;
      if (index % 2 === 1) {
        currentX = startX;
        currentY += imageHeight + fontSize + margin;
      }
    });

    // Save the PDF as a Blob
    const pdfBlob = doc.output("blob");
    const blobUrl = URL.createObjectURL(pdfBlob);
    setPdfBlobUrl(blobUrl);
    // let endTime = Date.now();
    // console.log("end time: ", endTime);
  }
  const handleBack = () => {
    setShowTextArea(true);
    setShowDownloadbtn(false);
    setText(null);
    setShowImage(false);
    setShowbackbtn(false);
    setShowOverAll(false);
  };

  return (
    <div className="center-wrapper">
      {showTextArea && (
        <div className="center-page">
          <form className="form" onSubmit={handleSubmit}>
            <textarea
              className="textarea"
              type="text"
              value={text}
              id="text"
              rows="4"
              cols="50"
              onChange={(event) => setText(event.target.value)}
            />
            <label className="label">
              Enter the serial numbers as comma separated
            </label>
            <button className="button" type="submit">
              Generate
            </button>
          </form>
          {/* <hr className="horizontal-line" /> */}
        </div>
      )}
      {( showOverAll &&
      <div className="img-cen">
        <div className="btn-pdf">
          {showbackbtn && (
            <IconButton
              className="btn-align"
              onClick={handleBack}
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {pdfBlobUrl && showDownloadbtn && (
            <a className="pdf-down" href={pdfBlobUrl} download="qrcodeImg.pdf">
              Download as a pdf file
            </a>
          )}
        </div>
        {qrCodeImages.length > 0 && showImage && (
          <div className="scrollable-container">
            <div className="img-grid">
              {qrCodeImages.map((qrCodeImage, index) => (
                // const textValue = textArray[intex]
                <div key={index} className="image-wrapper">
                  <img
                    src={qrCodeImage}
                    alt={`QR code ${qrCodeSerial[index + 1]}`}
                  />
                  <span className="serial-number">{qrCodeSerial[index]}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      )}
    </div>
  );
}

export default App;
