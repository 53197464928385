import React, { useState } from "react";
import "./roicalstyle.css";

function CalculatorForm() {
  const [teamValue, setTeamValue] = useState("");
  const [rateValue, setRateValue] = useState("");
  const [result, setResult] = useState("");
  const [isValid, setIsValid] = useState(true);
  const YearlyDays = 300;
  //const hours = 8;
  const onehoursave = 1.5;
  
  const handleChangeforteam = (event) => {
    if(event.target.value == null){
        setIsValid(false);
    }
    setTeamValue(event.target.value); 
    validateNumber(event.target.value);

  };

  const handleChangeforhour = (event) => {
    if(event.target.value == null){
      setIsValid(false);
  }
    setRateValue(event.target.value);
    validateNumber(event.target.value);
  };

  const validateNumber = (value) => {
    const numberRegex = /^[0-9]+$/;  // this is only allow number type
    setIsValid(numberRegex.test(value));
    console.log('validate ',numberRegex.test(value))
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(event == null){
        setIsValid(false);
    }

    // const data = {
    //   teamValue: parseInt(teamValue),
    //   rateValue: parseFloat(rateValue),
    // };
  
    // try {
    //   //const response = await fetch("http://localhost:3000/roi", {
    //   const response = await fetch("https://qr.prismworks.io/roi", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   });
  
    //   if (response.ok) {
    //     const result = await response.json();
    //     setResult(result.url);
    //   } else {
    //     // Handle the error case
    //     console.error("Request failed with status:", response.status);
    //   }
    // } catch (error) {
    //   console.error("Request failed with error:", error);
    // }

    let oneDaySave = onehoursave * rateValue;
    let YearlySave = oneDaySave * YearlyDays;
    let totalSaveTeam = YearlySave * teamValue;
    const numFor = Intl.NumberFormat('en-IN');
    const new_for = numFor.format(totalSaveTeam);
    setResult(new_for);
  };

  return (
    <div className="center-wrapper">
      <div className="center-page">
        {/* <h2 className="header">ROI Calculator</h2> */}
        <form className="form" onSubmit={handleSubmit}>
          <label className="label">Maintenance & Reliablity Team Size</label>
          <input
            type="text"
            value={teamValue}
            onChange={handleChangeforteam}
            //   placeholder="Enter a number"
            className="inputbtn"
          />
          <label className="label">Average hourly rate per team member</label>
          <input
            type="text"
            value={rateValue}
            onChange={handleChangeforhour}
            //   placeholder="Enter a number"
            className="inputbtn"
          />
          {!isValid && <p className="validate-txt">Please enter a valid number</p>}
          <button className="submitbtn" type="submit">
            Calculate
          </button>
        </form>
        <hr className="horizontal-line" />
        
        {result && isValid && 
        <label className="label-res">Estimated Yearly Savings</label>
        }
        {result && isValid && 
        <span className="span">${result} </span>
        }
      </div>
    </div>
  );
}

export default CalculatorForm;
